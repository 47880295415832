/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { DataTable } from "@roambee/client-styleguide";
// @ts-ignore
import { API, EventEmitter, generateQueryParams, getAuthUser } from "@roambee/client-utility";
import API_PATH from "../configs/APIConfigs";
import Shipment_Table_Columns from "../configs/ShipmentTableConfigs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

let tableConfiguration: any = localStorage.getItem("shipment_table");
if (tableConfiguration) {
	try {
		tableConfiguration = JSON.parse(tableConfiguration);
	} catch (error) {
		console.error("Error parsing 'shipment_table' from localStorage:", error);
	}
}

const parseFilterString = (filterString) => {
	if (filterString && filterString.length) {
		return filterString.split(" and ").map((filter) => {
			const [id, operation, value] = filter.match(/(\w+)\s(\w+)\s'(.+)'/).slice(1);
			return { id, value };
		});
	} else {
		return null;
	}
};

function ShipmentTable() {
	let urlFilters: any = {};
	const [searchParams] = useSearchParams();
	if (searchParams && searchParams.get("filter")) {
		urlFilters = JSON.parse(searchParams.get("filter"));
	}
	const user = getAuthUser();
	const [data, setData] = useState({ data: [], offset: 0, total: 0, size: 10 });
	const [customField, setCustomField] = useState([]);
	const [columnFilters, setColumnFilters] = useState(parseFilterString(urlFilters?.filter) || parseFilterString(tableConfiguration?.filter) || [{ id: "active", value: "1" }]);
	const [columnFilterModes, setColumnsFilterModes] = useState();
	const [loading, setLoading] = useState(true);
	const [sorting, setSorting] = useState(urlFilters?.sort || tableConfiguration?.sort || []);
	const [pagination, setPagination] = useState({
		pageIndex: urlFilters?.offset / 10 || tableConfiguration?.offset / 10 || 0,
		pageSize: urlFilters?.size || tableConfiguration?.size || 10
	});
	const [filterConfigs, setFilterConfigs] = useState([
		{
			label: "All",
			variant: "secondary",
			size: "small",
			onClick: () => handleFilterClick("All")
		},
		{
			label: "Active",
			variant: "primary",
			size: "small",
			onClick: () => handleFilterClick("Active")
		},
		{
			label: "Complete",
			variant: "secondary",
			size: "small",
			onClick: () => handleFilterClick("Inactive")
		}
		// Add more filter configurations as needed
	]);
	const columns = useMemo(() => Shipment_Table_Columns, []);

	const [toolbarConfigs, setToolbarConfigs] = useState([
		{
			label: "Share Link",
			variant: "secondary",
			size: "small",
			onClick: () => navigator.clipboard.writeText(window.location.href + `?filter=${localStorage.getItem("bee_table")}`)
		}
	]);

	const getShipment = useCallback(() => {
		const params = {
			offset: pagination.pageIndex * 10 || 0,
			size: pagination.pageSize || 10,
			fields: "all",
			filter: formatFiltersToString(columnFilters),
			sort: sorting
		};
		const queryString = generateQueryParams(params);
		setLoading(true);
		API("GET", `${API_PATH.SHIPMENTS}?${queryString}`)
			.then((result) => {
				const mergedData = result.data?.map((item) => ({
					...item,
					...item.custom_fields // Optionally spread custom_fields data directly into each item
				}));
				setData({
					...data, // Preserve existing properties like offset, total, size
					data: mergedData, // Update data property with mergedData
					offset: result?.offset,
					total: result?.total,
					size: result?.size
				});
				setLoading(false);
			})
			.catch((error) => {
				if (error.status === 404 && error.message) {
					console.error(error.message);
				} else {
					console.error("Other Error:", error);
					EventEmitter.emit("showSnackbar", {
						// icon: <WarningAlt size={20} />,
						id: "error",
						leftIcon: true,
						message: error?.message || "Something went wrong!",
						variant: "error",
						ariaLabel: "close button",
						position: "top-right"
					});
					setLoading(false);
				}
			});
		localStorage.setItem("bee_table", JSON.stringify(params));
	}, [columnFilters, sorting, pagination]);

	const getNestedValue = (object, path) => {
		return path?.split(".").reduce((acc, key) => acc && acc[key], object);
	};

	useEffect(() => {
		const params = {
			rbql: {
				filters: [
					{ name: "account_id", value: user?.account?.id },
					{ name: "active", value: 1 },
					{ name: "type", values: ["shipment", "package"] }
				],
				other_options: { return_all: true }
			}
		};
		const queryString = generateQueryParams(params);
		API("GET", `${API_PATH.AUTO_CRUD_CUSTOM_FIELDS}?${queryString}`)
			.then((result) => {
				const apiColumns = result.data.map((item) => ({
					accessorKey: `custom_fields.${item.fieldname.toLowerCase()}`,
					header: item.fieldname,
					isSortable: true,
					isSearchable: true,
					filterVariant: item.datatype === "select" ? "select" : undefined,
					Cell: ({ row, column }) => {
						const value = getNestedValue(row.original, column.accessorKey);
						return <div>{value || "-"}</div>;
					},
					filterSelectOptions: Array.isArray(item.values) ? JSON.parse(item.values).map((val) => val.value) : undefined
				}));
				setCustomField(apiColumns);
				setLoading(false);
			})
			.catch((error) => {
				console.error("API Error:", error);
				EventEmitter.emit("showSnackbar", {
					id: "error",
					leftIcon: true,
					message: error?.message || "Something went wrong!",
					variant: "error",
					ariaLabel: "close button",
					position: "top-right"
				});
				setLoading(false);
			});
	}, [user]);
	const combinedColumns = useMemo(() => [...columns, ...customField], [columns, customField]);
	useEffect(() => {
		getShipment();
	}, [getShipment]);

	function formatFiltersToString(filters) {
		if (filters.length === 0) {
			return ""; // Handle case where there are no filters (optional)
		}
		return filters
			.map((filter, index) => {
				return `${filter.id} ${index === 0 ? "eq" : "has"} '${filter.value}'`;
			})
			.join(" and ");
	}

	const handleFilterClick = (label) => {
		// Update variant in filterConfigs
		const updatedFilters = filterConfigs.map((filter) => ({
			...filter,
			variant: filter.label === label ? "primary" : "secondary"
		}));
		setFilterConfigs(updatedFilters);

		// Update columnFilters based on label
		if (label === "All") {
			// Remove filter with id: "active"
			setColumnFilters((prevFilters) => prevFilters.filter((filter) => filter.id !== "active"));
		} else {
			// Check if there is already a filter with id: "active"
			const existingFilterIndex = columnFilters.findIndex((filter) => filter.id === "active");

			if (existingFilterIndex !== -1) {
				// Update existing filter with id: "active"
				const updatedColumnFilters = [...columnFilters];
				updatedColumnFilters[existingFilterIndex].value = label === "Active" ? "1" : "0";
				setColumnFilters(updatedColumnFilters);
			} else {
				// Add new filter with id: "active"
				setColumnFilters((prevFilters) => [...prevFilters, { id: "active", value: label === "Active" ? "1" : "0" }]);
			}
		}
	};

	return (
		<section id="shipment-table">
			<div className="shipment-grid">
				<div className="grid-table">
					<DataTable title="Shipment" isSelectable={false} columnFilters={columnFilters} onColumnFiltersChange={setColumnFilters} rowCount={data.total} columns={combinedColumns} filterConfigs={filterConfigs} data={data?.data || []} isLoading={loading} columnFilterFns={columnFilterModes} onColumnFilterFnsChange={setColumnsFilterModes} sorting={sorting} onSortingChange={setSorting} onPaginationChange={setPagination} pagination={pagination} toolbarConfigs={toolbarConfigs} />
				</div>
			</div>
		</section>
	);
}

export default ShipmentTable;
