import ShipmentTable from "../components/ShipmentTable";

function Shipment() {
	return (
		<section id="shipment">
			<ShipmentTable></ShipmentTable>
		</section>
	);
}

export default Shipment;
