/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { formatDateTime } from "@roambee/client-utility";
// @ts-ignore
import { Tag, AppThemes } from "@roambee/client-styleguide";

const Shipment_Table_Columns = [
	{
		header: "Account Name",
		accessorKey: "account_name",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Name",
		accessorKey: "shipment_name",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue, row }) => {
			return renderedCellValue ? (
				<a href={`${process.env.CLIENT_HC10_URL}/shipments/details/${row.original.uuid}`} target="_blank" rel="noopener noreferrer">
					{renderedCellValue}
				</a>
			) : (
				"-"
			);
		}
	},
	{
		header: "Type",
		accessorKey: "shipment_type",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Bee Name",
		accessorKey: "bee_name",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Bee IMEI",
		accessorKey: "bee_imei",
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Origin",
		accessorKey: "orgi_location_name",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Orgin Detailed Name",
		accessorKey: "orgi_detailed_name",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Multi Modal Mode",
		accessorKey: "multi_modal_mode",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Destination",
		accessorKey: "desti_location_name",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Destination Detailed Name",
		accessorKey: "desti_detailed_name",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Created Date",
		accessorKey: "created_date_timestamp",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a") : "-")
	},
	{
		header: "Departure",
		accessorKey: "actual_departure", // sortaccessorKey: 'actual_departure_timestamp,scheduled_departure_timestamp',
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a") : "=")
	},
	{
		header: "Arrival",
		accessorKey: "scheduled_arrival_timestamp", // sortaccessorKey: 'actual_arrival_timestamp,scheduled_arrival_timestamp',
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a") : "=")
	},
	{
		header: "Google ETA:",
		accessorKey: "google_eta", // sortaccessorKey: 'google_eta',
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Predictive ETA:",
		accessorKey: "prediction_eta", // sortaccessorKey: 'prediction_eta',
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		header: "Battery",
		accessorKey: "battery_days",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => {
			return `${renderedCellValue} d`;
		}
	},
	{
		header: "Status",
		accessorKey: "shipment_status",
		value: "shipment_status",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c13",
		header: "Last Known Location",
		accessorKey: "last_known_location",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c15",
		header: "Last Known Times",
		accessorKey: "last_known_timestamp",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a") : "-")
	},
	{
		id: "c45",
		header: "Last Geo Alert",
		accessorKey: "last_geo_alert",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c16",
		header: "Temp",
		accessorKey: "temp",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c17",
		header: "MKT",
		accessorKey: "mkt",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c18",
		header: "Light",
		accessorKey: "light_state",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "N/A")
	},
	{
		id: "c19",
		header: "Pressure",
		accessorKey: "pressure",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c20",
		header: "Humidity",
		accessorKey: "humidity",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c21",
		header: "Ambient",
		accessorKey: "ambient",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c22",
		header: "Tilt",
		accessorKey: "tilt_shock",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c23",
		header: "Altitude",
		accessorKey: "altitude",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c24",
		header: "Tamper",
		accessorKey: "tamper",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "N/A")
	},
	{
		id: "c25",
		header: "Shock",
		accessorKey: "shock",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "N/A")
	},
	{
		id: "c26",
		header: "Distance Travelled",
		accessorKey: "distance_traveled",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c27",
		header: "Total Distance",
		accessorKey: "total_distance",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "N/A")
	},
	{
		id: "c28",
		header: "Transits Time",
		accessorKey: "in_transits",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	// {
	// 	id: "c29",
	// 	header: "Report",
	// 	accessorKey: "report",
	// 	width: 12,
	// 	isSortable: true,
	// 	isSearchable: true
	// },
	{
		id: "c30",
		header: "Terminated On",
		accessorKey: "terminated_on",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => formatDateTime(renderedCellValue, "LLL dd, yyyy hh:mm a")
	},
	{
		id: "c31",
		header: "Distance to Destination",
		accessorKey: "distance_to_destination",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c32",
		header: "Termination Reason",
		accessorKey: "termination_reason",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c33",
		header: "High Temperature Threshold",
		accessorKey: "high_temperature_threshold",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c34",
		header: "Highest Temperature Reached",
		accessorKey: "highest_temperature_reached",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c35",
		header: "Date w.r.t Highest Temperature",
		accessorKey: "highest_temperature_date",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c36",
		header: "Longest Duration Over High Temperature Threshold",
		accessorKey: "high_param_duration",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c37",
		header: "Reading Above High Temperature Threshold",
		accessorKey: "reading_above_high_param",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c38",
		header: "Temperature Standard Deviation",
		accessorKey: "temp_standard_deviation",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c39",
		header: "Low Temperature Threshold",
		accessorKey: "low_temperature_threshold",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c40",
		header: "Lowest Temperature Reached",
		accessorKey: "lowest_temperature_reached",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c41",
		header: "Date w.r.t Lowest Temperature",
		accessorKey: "lowest_temperature_date",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c42",
		header: "Longest Duration Over Low Temperature Threshold",
		accessorKey: "low_param_duration",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	},
	{
		id: "c44",
		header: "Reading Below Low Temperature Threshold",
		accessorKey: "reading_below_low_param",
		width: 12,
		isSortable: true,
		isSearchable: true,
		Cell: ({ renderedCellValue }) => (renderedCellValue ? renderedCellValue : "-")
	}
];

export default Shipment_Table_Columns;
